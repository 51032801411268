.App {
  text-align: center;
  background-color: #070c26;
  /* color: rgb(24, 27, 37); */
  color: #070c26;
  height: 100%;
  width: 100%;
}

.main-section {
  background-color: #070c26;
  height: 100vh;
  width: 100%;
}

.switch {
  position: inherit;
  background: transparent;
  justify-items: baseline;
}

.globe-container {
  display: inline-flex;
  fill-opacity: inherit;
  height: 80%;
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 0;
}

.timeline-container {
  position: absolute;
  bottom: 10vh;
  width: 100%;
}

.popup {
  position: absolute;
  z-index: 100;
  height: 30px;
  font-size: 16pt;
  width: 60px;
  border: 2px solid white;
  border-radius: 12px;
  background-color: white;
}

.info {
  position: absolute;
  z-index: 100;
  height: 80px;
  font-size: 10pt;
  width: 80px;
  border: 2px solid black;
  border-radius: 3px;
  background-color: white;
}

.checkbox Button {
  position: inherit;
  top: 250px;
  right: 60px;
  font-size: 25px;
  color: #f5eaea;
  font-weight: 800;
}
