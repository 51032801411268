.flexbox_container {
  display: flex;
  justify-content: space-between;
  z-index: 999;
  background: transparent;
  padding: 24px;
  position: fixed;
  width: 100%;
}

/* HEADER STYLES */
.title_item {
  color: rgba(0, 0, 0, 0.719);
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Helvetica;
  line-height: 18.4px;
  font-weight: 700;
  font-style: normal;
  text-align-last: left;
  text-shadow: blue;
  color: #ffffff;
  flex: 0 0 auto;
  margin-left: 2rem;
}

.logo_item {
  margin-right: 4px;
}

.header-nav {
  display: flex;
  float: right;
  flex: 0 0 auto;
  /* margin-top: 2rem;
  margin-right: 2.5rem; */
}

.header-nav-item {
  margin-left: 16px;
}

.date-picker-nav {
  position: fixed;
  top: 12vh;
  right: 8vw;
  z-index: 999;
  justify-content: left;
  align-items: left;
  justify-content: left;
}

/* .timeline-box {
  padding-left: '3px'
} */

.datepicker_container {
  padding: 2px;
}

h2 {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  background: #070c26;
  padding: 1rem;
  backdrop-filter: blur(16px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 4px;
}

.data-tooltip {
  position: absolute;
  color: #ffffff;
  width: 20vw;
  height: 226px;
  left: 10vw;
  top: 40vh;

  /* Darker Grey (80%) */

  background: #060e318c;
  backdrop-filter: blur(16px);
  border-radius: 4px;
  z-index: 999;
}

@media only screen and (max-width: 800px) {
  .title_item {
    font-size: 15px;
    padding: 0.5rem;
    text-align: left;
  }

  .logo_item {
    padding: 0.5rem;
    margin: 1px;
  }
}
.zoom-1 {
  position: fixed;
  top: 50vh;
  right: 24px;
  z-index: 999;
}

.guide-1 {
  position: fixed;
  bottom: 20px;
  right: 10px;
}

.overlay Button {
  position: absolute;
  bottom: 200px;
  right: 110px;
  color: rgba(223, 223, 23aaaaa6, 0.863);
  background-color: rgba(0, 66, 128, 0.856);
}

.cams-logo {
  position: absolute;
  border: 3px lightblue;
  height: 40px;
  bottom: 50px;
  left: 150px;
}

.navigation-container {
  position: absolute;
  right: 0;
  padding-right: 24px;
  top: 130%;
  color: white;
  text-align: left;
}

.globe-content {
  height: 100%;
  width: 100%;
  margin: auto;
}

.globe-container {
  display: block;
  fill-opacity: inherit;
  height: 100%;
  width: 100%;
}

.footer {
  position: fixed;
  bottom: -60px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  letter-spacing: 0.01em;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: 500;
  color: white;
  background: transparent;
  /* margin: 0 24px; */
}

.footer-credit {
  display: flex;
  margin-left: 24px;
}

.footer div div div p {
  position: inherit;
  float: left;
  font-size: 14px;
  line-height: 18.5px;
  margin-top: 1vh;
  margin-left: 1vw;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.footer-credit-section a {
  margin-left: 0.5vw;
  font-style: bold;
  font-weight: 600;
  font-size: 14px;
  line-height: 18.46px;
  color: #ffffff;
}

.footer-credit-section {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
}

.footer-credit-section + .footer-credit-section {
  margin-left: 22px;
}

.footer_nav {
  display: flex;
  margin-right: 20px;
}

.footer-nav-link {
  float: right;
  font-size: 14px;
  line-height: 18.46px;
  letter-spacing: 0.1em;
}

.footer-nav-link + .footer-nav-link {
  margin-left: 24px;
}

.pre-loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* TABLE SECTION STYLES */
.menu {
  padding: '0px';
}

.MuiSelect-select {
  padding-right: 2px !important;
}

.disappear {
  position: fixed;
  top: 10vh;
  right: 27vw;
  opacity: 0;
  z-index: 9999;
}
