@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'),
    url('./fonts/RobotoMono-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('Roboto Condensed'),
    url('./fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
}

body {
  margin: 0;
  font-family: Roboto Mono;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('./images/shy.jpg'); */
  /* background-color: #252c33;
   */
  background-color: #070c26;
  background-size: cover;
  height: 100%;
}
